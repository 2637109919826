<template>
  <div style="height: inherit" role="search" id="search">
    <v-container
      fluid
      role="form"
      :class="{
        'px-0': singleLine ? true : false,
        'pt-4': singleLine ? true : false,
      }"
    >
      <v-row class="ma-0 pa-0">
        <v-col
          :cols="singleLine ? 4 : 12"
          class="ma-0 pa-0"
          :class="{ 'pb-6': !singleLine }"
        >
          <v-expansion-panels focusable v-if="!isMobile" v-model="panel">
            <v-expansion-panel
              class="hiddenExpansionPanel"
              style="border-radius: 4px !important;"
              id="filterContent"
            >
              <v-expansion-panel-header
                expand-icon=""
                disable-icon-rotate
                style="border-radius: 4px !important"
                :class="{
                  blackOutline: singleLine ? false : true,
                  'rounded-xs': singleLine,
                }"
              >
                <v-container fluid class="pa-0 ma-0">
                  <v-row class="pa-0 ma-0">
                    <v-col class="pa-0 ma-0" cols="10">
                      <v-combobox
                        ref="searchBox"
                        @click.native.stop
                        class="ma-0 pa-0 my-1 removeInputBottomSpacing searchInputBox"
                        :class="{ 'rounded-xs': singleLine }"
                        label="Beruf, Stichwort, ..."
                        solo
                        flat
                        append-icon=""
                        v-model="searchString"
                        :disabled="disabled"
                        :items="jobsAndProfessions"
                        return-object
                        item-text="name"
                        :search-input.sync="searchString"
                        @keyup.enter="searchAndCloseMenu"
                        @keyup.space="preventOpenFilter"
                      >
                      </v-combobox>
                    </v-col>
                    <v-spacer />
                    <v-col
                      cols="auto"
                      class="pa-0 ma-0"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                    >
                      <v-icon
                        v-if="searchString != '' && !!searchString"
                        @click="clearSearchString"
                      >
                        mdi-close
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="1"
                      class="pa-0 ma-0"
                      style="display: flex; align-items: center"
                    >
                      <v-badge
                        color="primary"
                        :content="countActiveFilters()"
                        overlap
                        style="width: inherit; display: flex; justify-content: center"
                        :class="{ hiddenBadge: countActiveFilters() == 0 }"
                      >
                        <v-icon
                          @click="openFilter()"
                          style="
                            color: var(--v-primary-base) !important;
                            text-align: right;
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-badge>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="scrollable absoluteContent"
                style="min-height: 450px; max-height: 8vh"
              >
                <searchFilterComponent @search="search" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-combobox
            v-else
            :class="{
              blackOutline: singleLine ? false : true,
              'rounded-xs': singleLine,
            }"
            style="height: 56px; background-color: white;"
            @click.native.stop
            class="ma-0 my-1 pa-0 removeInputBottomSpacing searchInputBox"
            label="Beruf, Stichwort, ..."
            solo
            flat
            @click:append="showMobileFilter()"
            append-icon="mdi-filter-variant"
            disable-icon-rotate
            v-model="searchString"
            :disabled="disabled"
            :items="jobsAndProfessions"
            return-object
            item-text="name"
            :search-input.sync="searchString"
            clearable
          >
          </v-combobox>
        </v-col>
        <v-col cols="1" class="mb-0 pl-4 pr-0 py-0" v-if="!isMobile && singleLine">
          <v-btn
            class="rounded-xs"
            style="background-color: white !important; height: 100%"
            block
            large
            text
            @click="toggleFavorites"
          >
            <v-icon large color="primary" v-if="!showFavorites">
              mdi-star-outline
            </v-icon>
            <v-icon large color="primary" v-else>
              mdi-star
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          class="ma-0 pa-0"
          :class="{
            'px-2': singleLine,
            'pr-2': !singleLine,
            'pb-6': !singleLine,
          }"
          :cols="singleLine ? 4 : 8"
        >
          <v-combobox
            :disabled="disabled"
            class="ma-0 pa-0 removeInputBottomSpacing heightGrowCombobox"
            :class="{ 'rounded-xs': singleLine, 'px-2': singleLine }"
            :outlined="singleLine ? false : true"
            label="Stadt/ PLZ"
            :items="cities"
            solo
            flat
            clearable
            v-model="selectedCity"
            :filter="citySelectionFilter"
            :search-input.sync="cityFilter"
            return-object
            :menu-props="menuProps"
            id="cityInputSearch"
            ><template v-slot:item="{ item }">
              <v-container>
                <v-row>
                  <b class="pr-2">{{ item.city }}</b>
                  {{ item.state }}
                </v-row>
              </v-container>
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.city">
                <b class="pr-2">{{ item.city }}</b> {{ item.state }}
              </span>
            </template>
          </v-combobox>
        </v-col>

        <v-col
          :cols="singleLine ? 2 : 4"
          class="ma-0 pa-0 removeInputBottomSpacing"
        >
          <v-select
            v-if="(!cityFilter || cityFilter == '') && !selectedCity"
            :outlined="singleLine ? false : true"
            class="ma-0 pa-0"
            :class="{ 'rounded-xs': singleLine, 'pr-2': singleLine, heightGrowCombobox: singleLine }"
            :label="isMobile ? 'km' : 'Umkreis'"
            solo
            flat
            disabled
          ></v-select>
          <v-select
            v-else
            :outlined="singleLine ? false : true"
            class="ma-0 pa-0"
            :class="{ 'rounded-xs': singleLine, heightGrowCombobox: singleLine }"
            :label="isMobile ? 'km' : 'Umkreis'"
            solo
            flat
            :items="perimeterItems"
            v-model="perimeter"
            :disabled="disabled"
          ></v-select>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0" v-if="!singleLine">
          <v-btn
            :disabled="disabled"
            block
            large
            @click="search()"
            :class="{ darkOutline: isMobile && !singleLine }"
            >Suchen</v-btn
          >
        </v-col>
        <v-col cols="1" class="mb-0 pl-2 pr-0 py-0" v-else>
          <v-btn
            text
            :disabled="disabled"
            class="rounded-xs"
            style="background-color: white !important; height: 100%"
            block
            large
            @click="search()"
            ><v-icon style="color: var(--v-primary-base)" large
              >mdi-magnify</v-icon
            ></v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");

import searchFilterComponent from "../../components/Search/Filter.vue";

export default {
  name: "searchBar",

  metaInfo: {
    title:
      "Suche nach Ausbildungs- und Praktikumsstellen, Tätigkeitsfeldern oder BRAFO Lebenswelten",
    titleTemplate: "%s - Suche",
    htmlAttrs: {
      lang: "de",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Suche der digitalen Ausbildungs- und Praktikumsbörse der IHK Halle-Dessau",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  props: ["disabled", "singleLine", "typeFilter", "searchAgain"],

  data() {
    return {
      searchString: "",

      cityFilter: undefined,
      selectedCity: undefined,
      cities: [],

      perimeter: "Stadt",
      perimeterItems: ["Stadt", "10km", "15km", "25km", "50km", "100km"],

      mobileFilterVisible: false,

      showFavorites: false,

      filter: {
        startDateFilter: undefined,
        typeFilter: undefined,
        createdFilter: undefined,
        brafoFilter: undefined,
      },

      jobsAndProfessions: [],

      panel: undefined,
    };
  },

  components: {
    searchFilterComponent,
  },

  watch: {
    selectedCity() {
      if (
        this.$SearchFilterHandler.searchFilters.selectedCity !=
        this.selectedCity
      )
        this.$SearchFilterHandler.searchFilters.selectedCity = this.selectedCity;
    },
    perimeter() {
      if (this.$SearchFilterHandler.searchFilters.perimeter != this.perimeter)
        this.$SearchFilterHandler.searchFilters.perimeter = this.perimeter;
    },
    typeFilter() {
      this.filter.typeFilter = this.typeFilter;
    },

    cityFilter() {
      if (this.cityFilter) {
        if (!isNaN(parseInt(this.cityFilter)) && this.cityFilter.length > 1) {
          this.getCityAndPostcodes();
          return;
        } else if (this.cityFilter.length >= 1) {
          this.getCityAndPostcodes();
          return;
        }
      } else {
        this.selectedCity = undefined;
        this.cities = [];
      }
    },
    searchString() {
      if (this.searchString && this.searchString.length >= 1) {
        axios
          .get(
            axios.defaults.baseURL +
              "jobnames/search/?search_multi_match=" +
              this.searchString
          )
          .then((resp) => {
            this.jobsAndProfessions = resp.data;

            for (let i = this.jobsAndProfessions.length - 1; i >= 0; i--) {
              if (
                this.jobsAndProfessions[i].external &&
                this.jobsAndProfessions[i].Mapping
              ) {
                this.jobsAndProfessions.splice(i, 1);
              }
            }

            axios
              .get(
                axios.defaults.baseURL +
                  "professions/search/?search_multi_match=" +
                  this.searchString
              )
              .then((r) => {
                this.jobsAndProfessions = this.jobsAndProfessions.concat(
                  r.data
                );
              })
              .catch((e) => {
                console.log(e);
                window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              });
          })
          .catch((e) => {
            console.log(e);
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          });

        if (
          this.$SearchFilterHandler.searchFilters.searchString !=
          this.searchString
        )
          this.$SearchFilterHandler.searchFilters.searchString = this.searchString;
      } else {
        this.jobsAndProfessions = [];

        this.$SearchFilterHandler.searchFilters.searchString = "";
      }
    },
    searchAgain(){
      this.perimeter = this.$SearchFilterHandler.searchFilters.perimeter
      if(this.searchAgain && this.perimeterItems.indexOf(this.perimeter) != this.perimeterItems.length - 1){
        this.$SearchFilterHandler.searchFilters.perimeter = this.perimeterItems[this.perimeterItems.indexOf(this.perimeter) + 1]
        this.perimeter = this.perimeterItems[this.perimeterItems.indexOf(this.perimeter) + 1]
        
        this.$emit("search", true)
      }
    }
  },

  computed: {
    menuProps() {
      return !this.cityFilter ? { value: false } : {};
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    countActiveFilters() {
      let count = 0;
      if (this.$SearchFilterHandler.searchFilters.startDateFilter) ++count;

      if (this.$SearchFilterHandler.searchFilters.createdFilter) ++count;

      if (this.$SearchFilterHandler.searchFilters.typeFilter.ausbildung)
        ++count;

      if (this.$SearchFilterHandler.searchFilters.typeFilter.praktikum) ++count;

      for (
        let i = 0;
        i < this.$SearchFilterHandler.searchFilters.brafoSelections.length;
        i++
      ) {
        if (this.$SearchFilterHandler.searchFilters.brafoSelections[i]) {
          ++count;
        }
      }
      return "" + count;
    },
    clearSearchString(e) {
      this.searchString = "";

      e.stopPropagation();
    },
    openFilter() {
      this.countActiveFilters();
    },
    citySelectionFilter() {
      return true;
    },
    showMobileFilter() {
      this.mobileFilterVisible = true;
      this.$emit("showMobileFilter", true);
    },
    search() {
      this.showFavorites = false
      this.$emit("search");
    },
    getCityAndPostcodes(selectThis) {
      axios
        .get(
          axios.defaults.baseURL +
            "cities/?search=" +
            this.cityFilter +
            "&state=Sachsen-Anhalt"
        )
        .then((resp) => {
          let found_names = [];
          this.cities = [];
          for (let i = 0; i < resp.data.length; i++) {
            if (found_names.indexOf(resp.data[i].city) < 0) {
              this.cities.push(resp.data[i]);
              found_names.push(resp.data[i].city);
            }
          }

          if (selectThis) this.selectedCity = selectThis;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },

    checkAndCloseFilterPanel(e) {
      let currElement = e.target;
      let clickedOnChild = false;
      while (currElement) {
        if (currElement == document.getElementById("filterContent")) {
          clickedOnChild = true;
          break;
        }

        currElement = currElement.parentNode;
      }

      if (!clickedOnChild) {
        this.panel = undefined;
      }
    },
    preventOpenFilter(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    searchAndCloseMenu(e) {
      this.search();
      e.preventDefault();
      e.stopPropagation();
      this.$refs.searchBox.blur();
    },
    toggleFavorites(){
      this.showFavorites = !this.showFavorites;
      this.$emit("toggleFavorites", this.showFavorites)
    }
  },

  mounted() {
    document
      .getElementById("app")
      .addEventListener("click", this.checkAndCloseFilterPanel);

    this.getCityAndPostcodes(
      this.$SearchFilterHandler.searchFilters.selectedCity
    );

    this.searchString = this.$SearchFilterHandler.searchFilters.searchString;

    this.countActiveFilters();
  },

  beforeDestroy() {
    document
      .getElementById("app")
      .removeEventListener("click", this.checkAndCloseFilterPanel);
  },
};
</script>
